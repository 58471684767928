import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from "../components/layout-content";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About`}</h1>
    <p>{`Sector was built as the formalization of a content production
process developed and used by people that brought you
`}<a parentName="p" {...{
        "href": "https://gatsbyjs.org/docs/themes"
      }}>{`Gatsby Themes`}</a>{` and
`}<a parentName="p" {...{
        "href": "https://mdxjs.com"
      }}>{`MDX`}</a>{`.`}</p>
    <p>{`It is not a general CMS, it is built for a document-based
workflow and, as such, comes with critical features like
first class MDX support.`}</p>
    <p>{`While building themes we were inspired by the capabilities
themes + MDX afforded us and we began to write more, and more,
and more. We wrote over a hundred posts that year and developed
a process that allowed us to do so without giving up the rest
of our lives.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      